// Handle the video ticks.
app.videoTicker = function() {
  window.addEventListener('DOMContentLoaded', function (event) {
    // See if we want to enable this module.
    var body = document.getElementsByTagName('body')[0];

    if (!body.classList.contains('video-playback')) {
      return;
    }

    var video = document.getElementById('video');
    var videoId = video.getAttribute('data-video');

    if (video.hasAttribute('data-start') && video.hasAttribute('data-elapsed')) {
      var rememberVolume = Number(video.getAttribute('data-volume-remember'));
      var elapsedTime = Number(video.getAttribute('data-elapsed'));
      var startTime = Number(video.getAttribute('data-start'));
      var tick = 5000;
      var timer;
      var time1;
      var time2;

      if (rememberVolume == 1) {
        video.addEventListener('volumechange', function (event) {
          var postData = 'vol=' + Math.floor(video.volume * 100);

          var req = new XMLHttpRequest();
        req.onreadystatechange = processResponse;
        req.open("POST", "/a/vol");
        req.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
        req.send(postData);

        function processResponse() {
          if (req.readyState != 4) {
            return; // State 4 is DONE
          }
        }
        });
      }

      var atInterval = function() {
        time2 = Date.now() / 1000;
        elapsedTime += Math.round(time2 - time1);
        time1 = time2;

        var postData = "ct=" + Math.floor(video.currentTime) + "&et=" + elapsedTime;

        var req = new XMLHttpRequest();
        req.onreadystatechange = processResponse;
        req.open("POST", "/vt/" + videoId);
        req.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
        req.send(postData);

        function processResponse() {
          if (req.readyState != 4) {
            return; // State 4 is DONE
          }
        }
      };

      var dataLoaded = function() {
        video.currentTime = startTime;
        currentTimeSet = true;

        if (video.hasAttribute('data-volume-level')) {
          video.volume = video.getAttribute('data-volume-level') / 100;
        }
      };

      video.addEventListener("loadeddata", function() {
        if (video.readyState >= 2) {
          dataLoaded();
        }
      });

      video.addEventListener("play", function() {
        time1 = Date.now() / 1000;
        timer = setInterval(atInterval, tick);
      });

      video.addEventListener("pause", function() {
        clearInterval(timer);
        atInterval();
      });

      video.addEventListener("ended", function() {
        clearInterval(timer);
        atInterval();
      });
    }
  });

  return {
  };
}();

app.registration = function() {
  window.addEventListener('DOMContentLoaded', function (event) {
    // See if we want to enable this module.
    var body = document.getElementsByTagName('body')[0];

    if (!body.classList.contains('registration')) {
      return;
    }

    var provinces = new Map([
      ['CA', new Map([
        ['AB', 'Alberta'],
        ['BC', 'British Columbia'],
        ['MB', 'Manitoba'],
        ['NB', 'New Brunswick'],
        ['NL', 'Newfoundland and Labrador'],
        ['NT', 'Northwest Territories'],
        ['NS', 'Nova Scotia'],
        ['NU', 'Nunavut'],
        ['ON', 'Ontario'],
        ['PE', 'Prince Edward Island'],
        ['QC', 'Quebec'],
        ['SK', 'Saskatchewan'],
        ['YT', 'Yukon']
      ])],
      ['US', new Map([
        ['AL', 'Alabama'],
        ['AK', 'Alaska'],
        ['AS', 'American Samoa'],
        ['AZ', 'Arizona'],
        ['AR', 'Arkansas'],
        ['CA', 'California'],
        ['CO', 'Colorado'],
        ['CT', 'Connecticut'],
        ['DE', 'Delaware'],
        ['DC', 'District of Columbia'],
        ['FM', 'Federated States of Micronesia'],
        ['FL', 'Florida'],
        ['GA', 'Georgia'],
        ['GU', 'Guam'],
        ['HI', 'Hawaii'],
        ['ID', 'Idaho'],
        ['IL', 'Illinois'],
        ['IN', 'Indiana'],
        ['IA', 'Iowa'],
        ['KS', 'Kansas'],
        ['KY', 'Kentucky'],
        ['LA', 'Louisiana'],
        ['ME', 'Maine'],
        ['MH', 'Marshall Island'],
        ['MD', 'Maryland'],
        ['MA', 'Massachusetts'],
        ['MI', 'Michigan'],
        ['MN', 'Minnesota'],
        ['MS', 'Mississippi'],
        ['MO', 'Missouri'],
        ['MT', 'Montana'],
        ['NE', 'Nebraska'],
        ['NV', 'Nevada'],
        ['NH', 'New Hampshire'],
        ['NJ', 'New Jersey'],
        ['NM', 'New Mexico'],
        ['NY', 'New York'],
        ['NC', 'North Carolina'],
        ['ND', 'North Dakota'],
        ['MP', 'Northern Mariana Islands'],
        ['OH', 'Ohio'],
        ['OK', 'Oklahoma'],
        ['OR', 'Oregon'],
        ['PW', 'Palau'],
        ['PA', 'Pennsylvania'],
        ['PR', 'Puerto Rico'],
        ['RI', 'Rhode Island'],
        ['SC', 'South Carolina'],
        ['SD', 'South Dakota'],
        ['TN', 'Tennessee'],
        ['TX', 'Texas'],
        ['UT', 'Utah'],
        ['VT', 'Vermont'],
        ['VI', 'Virgin Islands'],
        ['VA', 'Virginia'],
        ['WA', 'Washington'],
        ['WV', 'West Virginia'],
        ['WI', 'Wisconsin'],
        ['WY', 'Wyoming']
      ])]
    ]);

    var countrySelect = document.getElementById('billingCountry');
    var selectedIndex = countrySelect.selectedIndex;
    var selectedCountry = countrySelect.options[selectedIndex].value;

    var provinceSelect = document.getElementById('billingProvince');
    var postedProvince = document.getElementById('postedBillingProvince');

    var setProvinces = function (countryAbbreviation, provinceAbbreviation) {
      $provinceExists = provinces.has(countryAbbreviation);

      if ($provinceExists) {
        var html = "<option value=\"--\">&mdash; Select &mdash;</option>\n";

        provinces.get(countryAbbreviation).forEach(function(value, key) {
          if (key != provinceAbbreviation) {
            html += '<option value="' + key + '">' + value + "</option>\n";
          }
          else {
            html += '<option value="' + key + '" selected>' + value + "</option>\n";
          }
        });

        provinceSelect.innerHTML = html;
        provinceSelect.disabled = false;
      }
      else {
        provinceSelect.disabled = true;
        provinceSelect.value = '--';

        var html = "<option value=\"--\">&mdash; Select &mdash;</option>\n";

        provinceSelect.innerHTML = html;
      }
    };

    var countryChanged = function (event) {
      selectedIndex = event.target.selectedIndex;
      selectedCountry = countrySelect.options[selectedIndex].value;

      setProvinces(selectedCountry, '--');
    };

    countrySelect.addEventListener('change', countryChanged);

    if (postedProvince.value !== '--') {
      setProvinces(selectedCountry, postedProvince.value);
    }
  });

  return {
  };
}();
